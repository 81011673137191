// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@tooltip--prefix-cls: ~"@{cls-prefix}tooltip";

.@{tooltip--prefix-cls} {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: @tooltip--z-index;
    max-inline-size: 240px;
    padding-block: 6px;
    padding-inline: 12px;
    border-radius: 2px;
    text-align: start;
    word-wrap: break-word;
    font-size: 12px;
    line-height: 1.333;
    color: #fff;
    background-color: #222;
    box-shadow: @token-shadow-2;
    pointer-events: none;

    b,
    i {
        font-weight: normal;
        font-style: normal;
    }
}
.touch .@{tooltip--prefix-cls} {
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;