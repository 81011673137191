// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Checkbox/variables";
@import "../Tabs/variables";

@card--prefix-cls: ~"@{cls-prefix}card";

@card--background-color: #fff;
@card--shadow: @token-shadow-1;
@card--border-color: @token-color-grey-3;
@card--selection-color: #76c8f4;
@card--header-color: @base-body-color;
@card--header-background-color: @token-color-grey-3;
@card--header-background-color-danger: @brand-danger;
@card--header-background-color-warning: @brand-warning;
@card--header-background-color-inactive: @token-color-grey-2;
@card--subtitle-color: @base-body-color;
@card--button-color: @token-color-primary-2;
@card--button-color-hover: @base-link-hover-color;
@card--button-color-active: @base-link-active-color;
@card--button-background-color-hover: rgb(34 34 34 / 0.05);
@card--button-background-color-active: rgb(34 34 34 / 0.09);
@card--button-header-color: @card--button-color;
@card--button-header-color-hover: @card--button-color-hover;
@card--button-header-color-active: @card--button-color-active;
@card--button-header-background-color-hover: @card--button-background-color-hover;
@card--button-header-background-color-active: @card--button-background-color-active;
@card--side-border-color: @card--border-color;
@card--spinner-duration: 1.4s;

.@{card--prefix-cls} {
    position: relative;
    border-radius: 4px;
    background-color: @card--background-color;
    box-shadow: @card--shadow;

    &__selection {
        display: flex;
        align-items: center;
        padding-inline-end: 12px;
        color: @card--selection-color;
        font-size: 20px;
        line-height: 24px;

        .@{checkbox--prefix-cls}__text {
            display: none;
        }

        .@{checkbox--prefix-cls}:hover {
            color: @card--header-color;
        }
    }

    &--row &__selection {
        margin: 0;
        padding: 12px;
        background-color: @card--header-background-color;
        border-start-start-radius: 4px;
        border-start-end-radius: 0;
        border-end-start-radius: 4px;
        border-end-end-radius: 0;
    }

    &:not(&--row) &__header {
        display: flex;
        margin: 0;
        padding-block: 8px;
        padding-inline: 16px;
        border-block-end: 1px solid @card--border-color;
        border-start-start-radius: 4px;
        border-start-end-radius: 4px;
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        color: @card--header-color;
        background-color: @card--header-background-color;
    }

    &--danger:not(&--row) &__header,
    &--danger&--row &__selection {
        background-color: @card--header-background-color-danger;
    }

    &--warning:not(&--row) &__header,
    &--warning&--row &__selection {
        background-color: @card--header-background-color-warning;
    }

    &--inactive:not(&--row) &__header,
    &--inactive&--row &__selection {
        background-color: @card--header-background-color-inactive;
    }

    &--row &__header {
        inline-size: 190px;
        min-inline-size: 0;
        padding-block: 16px;
        padding-inline: 24px 0;
    }

    &__title {
        display: flex;
        align-items: center;
        margin: 0;
        min-inline-size: 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: inherit;
    }

    &__subtitle {
        margin-block: 0 8px;
        margin-inline: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: @card--subtitle-color;
    }

    &:not(&--row) &__title {
        flex-grow: 1;
    }

    &__title-text,
    &__subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__labels,
    &__title-labels {
        display: flex;
        min-inline-size: 0;
        padding-block: 4px;
        padding-inline: 16px 0;
        align-self: center;
    }

    &__labels &__label,
    &__title-labels &__label {
        margin-inline-start: 8px;
        padding-block: 0;
        padding-inline: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 11px;
        line-height: 16px;
        border-radius: 8px;
    }

    &__buttons {
        box-sizing: border-box;
        display: flex;
        margin-inline-start: auto;
        padding-inline-start: 16px;
    }

    &--row &__buttons {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding-block: 14px;
        padding-inline: 16px;
        max-block-size: 92px;
    }

    &-button {
        display: inline-block;
        overflow: visible;
        box-sizing: border-box;
        min-block-size: 24px;
        min-inline-size: 24px;
        margin-block: 0;
        margin-inline: 4px 0;
        padding: 4px;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        fill: currentcolor;
        color: @card--button-color;
        border: none;
        border-radius: 2px;
        background-color: transparent;
        font: inherit;
        font-size: 12px;
        line-height: 1;

        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: @card--button-color-hover;
            outline: none;
            background-color: @card--button-background-color-hover;
        }

        &:active {
            color: @card--button-color-active;
            background-color: @card--button-background-color-active;
        }
    }

    &:not(&--row) &__header &-button {
        color: @card--button-header-color;

        &:hover,
        &:focus {
            color: @card--button-header-color-hover;
            background-color: @card--button-header-background-color-hover;
        }

        &:active {
            color: @card--button-header-color-active;
            background-color: @card--button-header-background-color-active;
        }
    }

    &--extended &__body,
    &--row &__body {
        display: flex;
    }

    &__side {
        display: flex;
        flex-flow: column;
        max-inline-size: 400px;
        flex: 0 0 40%;
        border-inline-end: 1px solid @card--side-border-color;

        > :last-child {
            block-size: 100%;
        }
    }

    &__side-header {
        flex: none;

        img {
            max-inline-size: 100%;
        }
    }

    &--extended &__main {
        flex: 1 1 60%;
        min-inline-size: 0;
    }

    &--row &__side-header {
        display: flex;
        align-items: center;
        max-inline-size: 124px;
        margin: 0;
        border-inline-end: none;
        background-color: @card--background-color;
    }

    &__side-content,
    &__main-content {
        padding: 16px;
    }

    &--row &__main-content {
        padding-block: 20px;
        padding-inline: 24px 0;
    }

    &--enter,
    &--exit {
        opacity: 0;
    }

    &--enter-active,
    &--exit-active {
        opacity: 1;
        transition: opacity 0.3s ease-in;
    }

    .@{tabs--prefix-cls}__tab--selected {
        .@{tabs--prefix-cls}__link,
        &:hover .@{tabs--prefix-cls}__link,
        .@{tabs--prefix-cls}__link:hover {
            border-block-end-color: @card--background-color;
            background-color: transparent;
        }
    }

    &__content-blocker {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        margin: -1px;
        background: rgb(255 255 255 / 0.8)
            url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCI+PHBhdGggZD0iTTktMWwyIDJNMCAwbDEwIDEwTS0xIDlsMiAyIiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L3N2Zz4=");
        background-size: 10px 10px;
    }

    &--progress {
        overflow: hidden;
        border-color: rgb(0 0 0 / 0.05);
    }

    &--progress &__inner {
        filter: blur(2px);
    }

    & &__preview-panel {
        border: none;
    }

    &:not(&--row) &__preview-panel {
        border-block-end: 1px solid @card--border-color;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;