// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "tokens";

@cls-prefix: ~"";

@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
@screen-xl: 1600px;
@screen-xl-min: @screen-xl;
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);
@screen-lg-max: (@screen-xl-min - 1);

// Base
// ========================================================================

@base-body-background: #fbfbfb;
@base-body-font-family: @token-font-family;
@base-body-font-weight: @token-font-wight-regular;
@base-body-font-size: @token-font-size-2;
@base-body-line-height: @token-line-height-4;
@base-body-color: @token-color-grey-10;

@base-link-color: #007eb0;
@base-link-hover-color: @token-color-primary-3;
@base-link-active-color: @token-color-primary-4;
@base-link-color-on-dark: @token-color-primary-5;
@base-link-hover-color-on-dark: @token-color-primary-6;
@base-link-active-color-on-dark: @token-color-primary-7;
@base-link-text-decoration: none;
@base-link-hover-text-decoration: underline;
@base-link-disabled-color: #979797;

@base-secondary-link-color: @base-link-color;
@base-secondary-link-hover-color: @base-link-hover-color;
@base-secondary-link-active-color: @base-link-active-color;
@base-muted-link-color: @base-link-color;
@base-muted-link-hover-color: @base-link-hover-color;
@base-muted-link-active-color: @base-link-active-color;

@base-code-color: #d05;
@base-code-font-size: 12px;
@base-code-font-family: "Consolas", "Liberation Mono", "Menlo", "Courier", monospace;

@base-margin-vertical: 16px;

@base-heading-font-family: @token-font-family;
@base-heading-font-weight-regular: @token-font-wight-regular;
@base-heading-font-weight-bold: @token-font-wight-semibold;
@base-heading-color: @base-body-color;
@base-heading-text-transform: none;
@base-heading-margin-top: 25px;

@base-h1-font-size: @token-font-size-6;
@base-h1-line-height: @token-line-height-1;
@base-h2-font-size: @token-font-size-5;
@base-h2-line-height: @token-line-height-3;
@base-h3-font-size: @token-font-size-4;
@base-h3-line-height: @token-line-height-2;
@base-h4-font-size: @token-font-size-3;
@base-h4-line-height: @token-line-height-1;
@base-h5-font-size: @token-font-size-2;
@base-h5-line-height: @token-line-height-4;
@base-h6-font-size: @token-font-size-1;
@base-h6-line-height: @token-line-height-3;

@base-list-padding-left: 20px;

@base-hr-margin-vertical: @base-margin-vertical;
@base-hr-border: @token-color-grey-3;
@base-hr-border-width: 1px;

@base-pre-background: @token-color-grey-2;
@base-pre-color: @base-body-color;
@base-pre-font-size: @base-body-font-size;
@base-pre-line-height: @token-line-height-4;
@base-pre-font-family: @base-code-font-family;

@base-selection-background: #39f;
@base-selection-color: #fff;

@brand-success: @token-color-success-1;
@brand-info: @token-color-primary-1;
@brand-warning: @token-color-warning-1;
@brand-danger: @token-color-danger-1;

@brand-success-light: lighten(@brand-success, 15%);
@brand-info-light: lighten(@brand-info, 5%);
@brand-warning-light: lighten(@brand-warning, 10%);
@brand-danger-light: lighten(@brand-danger, 9%);

@text-success-color: @token-color-success-2;
@text-info-color: @token-color-primary-2;
@text-warning-color: @token-color-warning-2;
@text-danger-color: @token-color-danger-2;
@text-muted-color: @token-color-grey-8;

@text-on-dark-success-color: @token-color-success-5;
@text-on-dark-info-color: @token-color-primary-5;
@text-on-dark-warning-color: @token-color-warning-5;
@text-on-dark-danger-color: @token-color-danger-5;
@text-on-dark-muted-color: rgb(255 255 255 / 0.65);

@import "./z-indexes.less";
