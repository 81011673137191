// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Label/variables";

@badge--prefix-cls: ~"@{cls-prefix}badge";

.@{badge--prefix-cls} {
    position: relative;
    display: inline-block;

    &__value {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 10px;
        transform: translateY(-50%) translateX(100%);
        z-index: 1;

        &.@{label--prefix-cls} {
            justify-content: center;
            min-inline-size: 16px;
            padding-block: 0;
            padding-inline: 4px;
            border-radius: 30px;
        }

        &--dot.@{label--prefix-cls} {
            inset-inline-end: 0;
            inline-size: 8px;
            block-size: 8px;
            min-inline-size: 8px;
            min-block-size: 8px;
            padding: 0;
            transform: translateY(-50%) translateX(50%);
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;