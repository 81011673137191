// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Section/variables";

.@{drawer--prefix-cls} {
    &__body {
        overflow: auto;
        position: relative;
        z-index: 1;
        flex-grow: 1;
    }

    &__inner-body {
        padding-block: 20px 24px;
        padding-inline: 24px;
    }

    &__form {
        padding-block: 20px 0;
        padding-inline: 24px;
    }

    &__footer {
        padding-inline: 24px;
        background-color: #fff;
        text-align: start;

        .@{section-item--prefix-cls}:not(.@{section-item--prefix-cls}--vertical)
            .@{section-item--prefix-cls}__title {
            order: 1;
            padding-inline-end: 0;
            padding-inline-start: 16px;
            inline-size: auto;
            text-align: end;
        }
    }

    &__footer-inner {
        padding-block: 16px 24px;
        border-block-start: 1px solid #d3d3de;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;