// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Icon/variables";
@import "../Spinner/variables";
@import "../../helpers/mixins";

/*
Button

Markup:
<button class="button .modifier" type="button" :modifier>Link</button>
<a class="button .modifier" role="button" tabindex="0">Button</a>

Modifiers:
.button--primary
.button--secondary
.button--success
.button--warning
.button--danger
.button--info

.button--active
.button--loading
.button--hovered
.button--focused
.button--disabled
*/

.@{button--prefix-cls} {
    display: inline-block;
    overflow: visible;
    box-sizing: border-box;
    min-block-size: @button--height;
    min-inline-size: 70px;
    margin: 0;
    padding-block: @button--padding-vertical;
    padding-inline: @button--padding-horizontal;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    text-transform: none;
    border: none;
    border-radius: 2px;
    font: inherit;
    font-size: @button--font-size;
    font-weight: @button--font-weight;
    line-height: @button--line-height;
    color: @button--color;
    background-color: @button--background-color;
    box-shadow: @button--shadow;
    transition: background-color linear 0.2s, box-shadow linear 0.2s, opacity linear 0.2s;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &--arrow {
        position: relative;
        margin-inline-end: 8px;
    }

    &--arrow-backward {
        margin-inline-end: 0;
        margin-inline-start: 8px;
    }

    &__arrow {
        position: absolute;
        inline-size: 12px;
        block-size: @button--height;
        inset-block-start: 0;
        inset-inline-end: -11px;
        overflow: hidden;

        &::before {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 1px;
            content: "";
            inline-size: 24px;
            block-size: 15px;
            border-radius: 2px;
            transform: rotate(45deg) translateX(-50%) skew(18deg, 18deg);
            transform-origin: 0 50%;
            background-color: @button--background-color;
            box-shadow: @button--arrow-arrow-shadow;
            transition: background-color linear 0.2s, box-shadow linear 0.2s;
        }
    }

    &--arrow-backward &__arrow {
        inset-inline-start: -11px;
        transform: scaleX(-1);
    }

    &--arrow&--lg {
        margin-inline-end: 10px;
    }

    &--arrow-backward&--lg {
        margin-inline-end: 0;
        margin-inline-start: 10px;
    }

    &--arrow&--lg &__arrow {
        inline-size: 14px;
        block-size: @button--height-large;
        inset-inline-end: -13px;

        &::before {
            inset-block-start: 0;
            inset-inline-start: 1px;
            inline-size: 32px;
            block-size: 24px;
            border-radius: 2px;
            transform: rotate(45deg) translateX(-50%) skew(20deg, 20deg);
        }
    }

    &--arrow-backward&--lg &__arrow {
        inset-inline-start: -13px;
    }

    &:hover,
    &:focus,
    &.@{button--prefix-cls}--focused,
    &.@{button--prefix-cls}--hovered {
        text-decoration: none;
        color: @button--color-hover;
        background-color: @button--background-color-hover;
        box-shadow: @button--shadow-hover;

        .@{button--prefix-cls}__arrow::before {
            background-color: @button--background-color-hover;
            box-shadow: @button--arrow-arrow-shadow-hover;
        }
    }

    &:focus,
    &.@{button--prefix-cls}--focused {
        box-shadow: @button--shadow-focus;
        outline: none;
    }

    &--arrow:focus,
    &--arrow.@{button--prefix-cls}--focused {
        box-shadow: @button--arrow-shadow-focus;

        .@{button--prefix-cls}__arrow::before {
            box-shadow: @button--arrow-arrow-shadow-focus;
        }
    }

    &:active,
    &.@{button--prefix-cls}--active {
        color: @button--color-active;
        background-color: @button--background-color-active;
        box-shadow: @button--shadow-active;

        .@{button--prefix-cls}__arrow::before {
            background-color: @button--background-color-active;
            box-shadow: @button--arrow-arrow-shadow-active;
        }
    }

    &:active,
    &.@{button--prefix-cls}--active:focus {
        box-shadow: @button--shadow-active-focus;
    }

    &--arrow:active,
    &--arrow.@{button--prefix-cls}--active:focus {
        box-shadow: @button--arrow-shadow-active-focus;

        .@{button--prefix-cls}__arrow::before {
            box-shadow: @button--arrow-arrow-shadow-active-focus;
        }
    }

    &[disabled],
    &--disabled,
    &--loading {
        &,
        &:hover,
        &:focus,
        &:active {
            color: @button--color;
            background-color: @button--background-color;
            cursor: not-allowed;
            opacity: 0.47;
            box-shadow: @button--shadow;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--background-color;
                box-shadow: @button--arrow-arrow-shadow;
            }
        }
    }

    &--primary {
        &,
        &[disabled],
        &.@{button--prefix-cls}--disabled,
        &.@{button--prefix-cls}--loading {
            &,
            &:hover,
            &:focus,
            &:active {
                color: @button--primary-color;
                background-color: @button--primary-background-color;

                // stylelint-disable max-nesting-depth
                .@{button--prefix-cls}__arrow::before {
                    background-color: @button--primary-background-color;
                }
                // stylelint-enable
            }
        }

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--hovered {
            color: @button--primary-color-hover;
            background-color: @button--primary-background-color-hover;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--primary-background-color-hover;
            }
        }

        &:active,
        &.@{button--prefix-cls}--active {
            color: @button--primary-color-active;
            background-color: @button--primary-background-color-active;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--primary-background-color-active;
            }
        }

        &.@{button--prefix-cls}--loading .@{spinner--prefix-cls}__path {
            stroke: #fff;
            animation: @spinner--animation-dash @spinner--animation-duration ease-in-out infinite;
        }
    }

    &--success {
        &,
        &[disabled],
        &.@{button--prefix-cls}--disabled,
        &.@{button--prefix-cls}--loading {
            &,
            &:hover,
            &:focus,
            &:active {
                color: @button--success-color;
                background-color: @button--success-background-color;

                // stylelint-disable max-nesting-depth
                .@{button--prefix-cls}__arrow::before {
                    background-color: @button--success-background-color;
                }
                // stylelint-enable
            }
        }

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--hovered {
            color: @button--success-color-hover;
            background-color: @button--success-background-color-hover;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--success-background-color-hover;
            }
        }

        &:active,
        &.@{button--prefix-cls}--active {
            color: @button--success-color-active;
            background-color: @button--success-background-color-active;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--success-background-color-active;
            }
        }
    }

    &--warning {
        &,
        &[disabled],
        &.@{button--prefix-cls}--disabled,
        &.@{button--prefix-cls}--loading {
            &,
            &:hover,
            &:focus,
            &:active {
                color: @button--warning-color;
                background-color: @button--warning-background-color;

                // stylelint-disable max-nesting-depth
                .@{button--prefix-cls}__arrow::before {
                    background-color: @button--warning-background-color;
                }
                // stylelint-enable
            }
        }

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--hovered {
            color: @button--warning-color-hover;
            background-color: @button--warning-background-color-hover;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--warning-background-color-hover;
            }
        }

        &:active,
        &.@{button--prefix-cls}--active {
            color: @button--warning-color-active;
            background-color: @button--warning-background-color-active;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--warning-background-color-active;
            }
        }
    }

    &--danger {
        &,
        &[disabled],
        &.@{button--prefix-cls}--disabled,
        &.@{button--prefix-cls}--loading {
            &,
            &:hover,
            &:focus,
            &:active {
                color: @button--danger-color;
                background-color: @button--danger-background-color;

                // stylelint-disable max-nesting-depth
                .@{button--prefix-cls}__arrow::before {
                    background-color: @button--danger-background-color;
                }
                // stylelint-enable
            }
        }

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--hovered {
            color: @button--danger-color-hover;
            background-color: @button--danger-background-color-hover;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--danger-background-color-hover;
            }
        }

        &:active,
        &.@{button--prefix-cls}--active {
            color: @button--danger-color-active;
            background-color: @button--danger-background-color-active;

            .@{button--prefix-cls}__arrow::before {
                background-color: @button--danger-background-color-active;
            }
        }
    }

    &--selected {
        color: @button--color-active;
        background-color: @button--background-color-active;
        box-shadow: @button--shadow-active;

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &:active,
        &.@{button--prefix-cls}--hovered,
        &.@{button--prefix-cls}--active {
            color: @button--color-active;
            background-color: @button--background-color-active;
            box-shadow: @button--shadow-active;
        }

        &:focus,
        &.@{button--prefix-cls}--focused {
            box-shadow: @button--shadow-active-focus;
        }
    }

    &--loading {
        position: relative;
        cursor: default;
        pointer-events: none;

        .@{button--prefix-cls}__spinner {
            inline-size: 12px;
            block-size: 12px;
            margin-inline-end: 4px;
            margin-inline-start: -4px;
            vertical-align: top;
        }

        &.@{button--prefix-cls}--empty .@{button--prefix-cls}__spinner {
            inline-size: 16px;
            block-size: 16px;
        }

        .@{button--prefix-cls}__icon {
            display: none;
        }
    }

    &__icon {
        flex: none;
        margin-inline-end: 6px;
        margin-inline-start: -8px;
        vertical-align: top;
    }

    &__icon:not(.@{icon--prefix-cls}--intent) {
        color: @button--icon-color;
    }

    &__caret {
        vertical-align: 0;
        margin-inline-start: 2px;
        margin-inline-end: -12px;
        color: @button--icon-color;
    }

    &--caret,
    &--empty {
        min-inline-size: 28px;
    }

    &--empty {
        padding: @button--padding-vertical;

        .@{button--prefix-cls}__icon,
        .@{button--prefix-cls}__spinner,
        .@{icon--prefix-cls} {
            margin: 0;
        }

        &.@{button--prefix-cls}--loading::before {
            margin-inline-end: 0;
        }

        .@{button--prefix-cls}__caret {
            margin-inline-start: 0;
            margin-inline-end: 0;
        }

        .@{button--prefix-cls}__icon ~ .@{button--prefix-cls}__caret,
        .@{button--prefix-cls}__spinner ~ .@{button--prefix-cls}__caret {
            margin-inline-start: 2px;
            margin-inline-end: -3px;
        }
    }

    &--empty&--caret {
        padding-block: @button--padding-vertical;
        padding-inline: 8px;
    }

    &--empty&--ghost {
        padding-inline-end: 6px;
        padding-inline-start: 6px;
    }

    &--fill {
        display: block;
        inline-size: 100%;
    }

    &--primary,
    &--success,
    &--warning,
    &--danger {
        .@{button--prefix-cls}__icon {
            color: currentcolor;
        }

        .@{button--prefix-cls}__caret {
            color: @button--primary-color;
        }
    }

    &--ghost {
        padding-inline-start: 8px;
        padding-inline-end: 8px;
        min-inline-size: 28px;

        &,
        &[disabled],
        &.@{button--prefix-cls}--disabled,
        &.@{button--prefix-cls}--loading {
            &,
            &:hover,
            &:focus,
            &.@{button--prefix-cls}--focused,
            &:active {
                color: @button--ghost-color;
                background-color: transparent;
                box-shadow: none;
            }
        }

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--hovered {
            color: @button--ghost-color;
            background-color: @token-color-grey-a2;
            box-shadow: none;
        }

        &:active,
        &.@{button--prefix-cls}--active,
        &.@{button--prefix-cls}--selected {
            color: @button--ghost-color;
            background-color: @token-color-grey-a3;
            box-shadow: none;
        }

        &:focus,
        &.@{button--prefix-cls}--focused,
        &:active,
        &.@{button--prefix-cls}--selected:focus,
        &.@{button--prefix-cls}--active:focus {
            box-shadow: @button--ghost-shadow-focus;
        }

        .@{button--prefix-cls}__icon {
            margin-inline-start: 0;
            opacity: 1;
        }

        .@{button--prefix-cls}__caret {
            margin-inline-end: 0;
            color: @button--icon-color;
        }

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &:active,
        &.@{button--prefix-cls}--hovered,
        &.@{button--prefix-cls}--active,
        &.@{button--prefix-cls}--selected {
            &:not([disabled]) .@{button--prefix-cls}__icon:not(.@{icon--prefix-cls}--intent),
            &:not(.@{button--prefix-cls}--disabled)
                .@{button--prefix-cls}__icon:not(.@{icon--prefix-cls}--intent) {
                color: @base-body-color;
            }
        }
    }

    &--lg {
        min-block-size: @button--height-large;
        min-inline-size: 100px;
        padding-block: @button--padding-vertical-large;
        padding-inline: @button--padding-horizontal-large;
        font-size: @button--font-size-large;

        .@{button--prefix-cls}__icon {
            margin-inline-end: 8px;
            margin-inline-start: -8px;
        }

        .@{button--prefix-cls}__caret {
            margin-inline-start: 4px;
            margin-inline-end: -12px;
        }

        &.@{button--prefix-cls}--caret,
        &.@{button--prefix-cls}--empty {
            min-inline-size: 40px;
        }
    }

    &--lg&--empty {
        padding: @button--padding-vertical-large;

        .@{button--prefix-cls}__icon,
        .@{button--prefix-cls}__spinner,
        .@{icon--prefix-cls} {
            margin: 0;
        }

        &.@{button--prefix-cls}--loading::before {
            margin-inline-end: 0;
        }

        .@{button--prefix-cls}__caret {
            margin-inline-start: 0;
            margin-inline-end: 0;
        }

        .@{button--prefix-cls}__icon ~ .@{button--prefix-cls}__caret,
        .@{button--prefix-cls}__spinner ~ .@{button--prefix-cls}__caret {
            margin-inline-start: 4px;
            margin-inline-end: -3px;
        }

        &.@{button--prefix-cls}--caret {
            padding-block: @button--padding-vertical-large;
            padding-inline: 12px;
        }

        &.@{button--prefix-cls}--ghost {
            padding-inline-end: 10px;
            padding-inline-start: 10px;
        }
    }
}

// stylelint-disable-next-line no-duplicate-selectors
.@{button--prefix-cls} {
    // on-dark
    &--on-dark:focus,
    &--on-dark.@{button--prefix-cls}--focused {
        box-shadow: @button--on-dark-shadow-focus;
    }

    &--on-dark&--arrow:focus,
    &--on-dark&--arrow.@{button--prefix-cls}--focused {
        box-shadow: @button--on-dark-arrow-shadow-focus;

        .@{button--prefix-cls}__arrow::before {
            box-shadow: @button--on-dark-arrow-arrow-shadow-focus;
        }
    }

    &--on-dark:active,
    &--on-dark&.@{button--prefix-cls}--active:focus {
        box-shadow: @button--on-dark-shadow-active-focus;
    }

    &--on-dark&--arrow:active,
    &--on-dark&--arrow.@{button--prefix-cls}--active:focus {
        box-shadow: @button--on-dark-arrow-shadow-active-focus;

        .@{button--prefix-cls}__arrow::before {
            box-shadow: @button--on-dark-arrow-arrow-shadow-active-focus;
        }
    }

    &--on-dark&--ghost {
        &,
        &[disabled],
        &.@{button--prefix-cls}--disabled,
        &.@{button--prefix-cls}--loading {
            &,
            &:hover,
            &:focus,
            &:active {
                color: @button--on-dark-ghost-color;
            }
        }

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--hovered {
            color: @button--on-dark-ghost-color;
            background-color: rgb(255 255 255 / 0.09);
        }

        &:active,
        &.@{button--prefix-cls}--active,
        &.@{button--prefix-cls}--selected {
            color: @button--on-dark-ghost-color;
            background-color: rgb(255 255 255 / 0.05);
        }

        &:focus,
        &.@{button--prefix-cls}--focused,
        &:active,
        &.@{button--prefix-cls}--selected:focus,
        &.@{button--prefix-cls}--active:focus {
            box-shadow: @button--on-dark-ghost-shadow-focus;
        }

        .@{button--prefix-cls}__icon:not(.@{icon--prefix-cls}--intent),
        .@{button--prefix-cls}__caret {
            color: rgb(255 255 255 / 0.65);
        }

        &:hover,
        &:focus,
        &:active,
        &.@{button--prefix-cls}--hovered,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--active,
        &.@{button--prefix-cls}--selected {
            &:not([disabled]) .@{button--prefix-cls}__icon:not(.@{icon--prefix-cls}--intent),
            &:not(.@{button--prefix-cls}--disabled)
                .@{button--prefix-cls}__icon:not(.@{icon--prefix-cls}--intent) {
                color: rgb(255 255 255 / 0.9);
            }
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;