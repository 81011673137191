// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";

.label(@intent, @bg: @label-background) {
    &--@{intent} {
        background-color: ~"var(--@{label--prefix-cls}-@{intent}-background, @{bg})";

        &:where(a, button):is(:hover, :focus) {
            background-color: %(
                ~"var(--@{label--prefix-cls}-@{intent}-background-hovered, %a)",
                darken(@bg, 5%)
            );
        }

        &:where(a, button):is(:active) {
            background-color: %(
                ~"var(--@{label--prefix-cls}-@{intent}-background-pressed, %a)",
                darken(@bg, 10%)
            );
        }
    }
}

.label-light(@intent, @color: @label-on-color, @bg: @label-light-background) {
    &--light:where(&--@{intent}) {
        color: ~"var(--@{label--prefix-cls}-light-@{intent}-color, @{color})";
        background-color: ~"var(--@{label--prefix-cls}-light-@{intent}-background, @{bg})";

        &:where(a, button):is(:hover, :focus) {
            color: ~"var(--@{label--prefix-cls}-light-@{intent}-color-hovered, @{color})";
            background-color: %(
                ~"var(--@{label--prefix-cls}-light-@{intent}-background-hovered, %a)",
                darken(@bg, 5%)
            );
        }

        &:where(a, button):is(:active) {
            color: ~"var(--@{label--prefix-cls}-light-@{intent}-color-pressed, @{color})";
            background-color: %(
                ~"var(--@{label--prefix-cls}-light-@{intent}-background-pressed, %a)",
                darken(@bg, 10%)
            );
        }
    }
}

.label-outline(
    @intent,
    @color: inherit,
    @border: inherit,
    @bg: color-mix(in srgb, @color 5%, #fff),
    @bg-pressed: color-mix(in srgb, @color 10%, #fff)
) {
    &--outline:where(&--@{intent}) {
        color: ~"var(--@{label--prefix-cls}-outline-@{intent}-color, @{color})";
        background-color: ~"var(--@{label--prefix-cls}-outline-@{intent}-background, transparent)";
        border-color: ~"var(--@{label--prefix-cls}-outline-@{intent}-border-color, @{border})";

        &:where(a, button):is(:hover, :focus) {
            color: ~"var(--@{label--prefix-cls}-light-@{intent}-color-hovered, @{color})";
            background-color: ~"var(--@{label--prefix-cls}-outline-@{intent}-background-hovered, @{bg})";
            border-color: ~"var(--@{label--prefix-cls}-outline-@{intent}-border-color-hovered, @{border})";
        }

        &:where(a, button):is(:active) {
            color: ~"var(--@{label--prefix-cls}-light-@{intent}-color-pressed, @{color})";
            background-color: ~"var(--@{label--prefix-cls}-outline-@{intent}-background-pressed, @{bg-pressed})";
            border-color: ~"var(--@{label--prefix-cls}-outline-@{intent}-border-color-pressed, @{border})";
        }
    }
}

.@{label--prefix-cls} {
    box-sizing: border-box;
    display: inline-flex;
    gap: ~"var(--@{label--prefix-cls}-gap, 4px)";
    align-items: center;
    min-block-size: ~"var(--@{label--prefix-cls}-min-block-size, 16px)";
    padding-block: ~"var(--@{label--prefix-cls}-padding-block, 0)";
    padding-inline: ~"var(--@{label--prefix-cls}-padding-inline, 4px)";
    font-size: ~"var(--@{label--prefix-cls}-font-size, 12px)";
    font-weight: ~"var(--@{label--prefix-cls}-font-weight, 600)";
    line-height: ~"var(--@{label--prefix-cls}-line-height, 16px)";
    color: ~"var(--@{label--prefix-cls}-color, @{label-color})";
    text-decoration: none;
    vertical-align: middle;
    background-color: ~"var(--@{label--prefix-cls}-background, @{label-background})";
    border: none;
    border-radius: ~"var(--@{label--prefix-cls}-border-radius, 2px)";
    appearance: none;

    &:where(a, button) {
        cursor: pointer;

        &:focus-visible {
            outline: ~"var(--@{label--prefix-cls}-focus-visible, 4px solid @{label-focus-outline})";
        }
    }

    &:where(a, button):is(:hover, :focus) {
        color: ~"var(--@{label--prefix-cls}-color-hovered, @{label-color})";
        text-decoration: none;
        background-color: %(
            ~"var(--@{label--prefix-cls}-background-hovered, %a)",
            darken(@label-background, 5%)
        );
    }

    &:where(a, button):is(:active) {
        color: ~"var(--@{label--prefix-cls}-color-pressed, @{label-color})";
        background-color: %(
            ~"var(--@{label--prefix-cls}-background-pressed, %a)",
            darken(@label-background, 10%)
        );
    }

    &--outline {
        border: 1px solid;
    }

    .label(info, @label-info-background);
    .label(success, @label-success-background);
    .label(warning, @label-warning-background);
    .label(danger, @label-danger-background);

    .label-light(inactive, @label-inactive-color, @label-light-inactive-background);
    .label-light(info, @label-info-color, @label-light-info-background);
    .label-light(success, @label-success-color, @label-light-success-background);
    .label-light(warning, @label-warning-color, @label-light-warning-background);
    .label-light(danger, @label-danger-color, @label-light-danger-background);

    .label-outline(inactive, @label-inactive-color, @label-inactive-border-color);
    .label-outline(info, @label-info-color, @label-info-border-color);
    .label-outline(success, @label-success-color);
    .label-outline(warning, @label-warning-color);
    .label-outline(danger, @label-danger-color);

    &--md {
        min-block-size: ~"var(--@{label--prefix-cls}-md-min-block-size, 24px)";
        padding-block: ~"var(--@{label--prefix-cls}-md-padding-block, 2px)";
    }

    &--lg {
        min-block-size: ~"var(--@{label--prefix-cls}-lg-min-block-size, 28px)";
        padding-block: ~"var(--@{label--prefix-cls}-lg-padding-block, 4px)";
        padding-inline: ~"var(--@{label--prefix-cls}-lg-padding-inline, 6px)";
        font-size: ~"var(--@{label--prefix-cls}-lg-font-size, 14px)";
    }

    &--caps {
        font-size: ~"var(--@{label--prefix-cls}-caps-font-size, 11px)";
        text-transform: uppercase;
        letter-spacing: ~"var(--@{label--prefix-cls}-caps-letter-spacing, 0.5px)";
    }

    &--caps:where(&--lg) {
        font-size: ~"var(--@{label--prefix-cls}-lg-caps-font-size, 12px)";
    }

    &__icon {
        flex: none;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;