// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../../helpers/mixins";
@import "variables";
@import "../Menu/variables";
@import "../Dropdown/variables";
@import "../Button/variables";
@import "../Icon/variables";

/*
Tabs

Markup:
<div class="tabs">
  <div class="tabs__nav" role="tablist">
    <ul class="tabs__nav-list">
      <li class="tabs__tab {{.modifier}}" role="presentation" aria-selected="true">
        <a class="tabs__link" role="tab">
          <span class="tabs__tab-icon">{{icon}}</span>
          <span class="tabs__tab-title">{{title}}</span>
        </a>
        <button class="tabs__tab-close" type="button"><span class="icon tabs__tab-close-icon"><svg></svg></span></button>
      </li>
    </ul>
    <div class="tabs__addon"></div>
  </div>
  <div class="tabs__content" role="tabpanel">{{children}}</div>
</div>

Modifiers:
.tabs__tab--selected
*/

.@{tabs--prefix-cls} {
    &__nav {
        position: relative;
        display: flex;
        align-items: flex-end;
        margin-block-end: 16px;
    }

    &__tab-list {
        display: flex;
        flex: 1 0 auto;
        margin: 0;
        padding: 0;
        list-style: none;
        max-inline-size: 100%;
        border-block-end: 2px solid @tabs--border-color;
    }

    &__tab {
        flex: none;
    }

    &__tab:not(:first-child) &__link {
        margin-inline-start: 24px;
    }

    &__link {
        display: flex;
        flex-grow: 1;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        position: relative;
        block-size: 26px;
        line-height: 16px;
        padding-block: 3px;
        padding-inline: 0;
        margin-block-end: 0;
        white-space: nowrap;
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        color: @tabs--color;
        background-color: transparent;
        cursor: pointer;

        .@{tabs--prefix-cls}__tab-icon,
        .@{tabs--prefix-cls}__tab-title,
        .@{tabs--prefix-cls}__tab-close {
            z-index: 1;
        }

        &::before {
            content: "";
            position: absolute;
            inset-inline-end: 0;
            inset-inline-start: 0;
            inset-block-end: -2px;
            block-size: 2px;
            inline-size: 100%;
            background-color: transparent;
            transition: 0.18s ease-out;
        }

        &:focus {
            outline: none;

            &::before {
                background-color: @tabs--indicator-color-hover;
            }

            &::after {
                content: "";
                position: absolute;
                inset-inline-end: -4px;
                inset-inline-start: -4px;
                inset-block-end: -2px;
                inset-block-start: 0;
                border-radius: 2px;
                box-shadow: @tabs--focus-shadow;
            }
        }
    }

    &__dropdown &__link,
    &__tab--more &__link {
        padding: 6px;
    }

    &__link:hover,
    &__tab:hover &__link,
    &__tab:not(&__tab--selected) .@{dropdown--prefix-cls}--opened &__link,
    &__tab:not(&__tab--selected) .@{dropdown--prefix-cls}--opened &__link:hover,
    &__tab:not(&__tab--selected):hover .@{dropdown--prefix-cls}--opened &__link {
        color: @tabs--color-hover;
        background-color: transparent;
        text-decoration: none;

        &::before {
            background-color: @tabs--indicator-color-hover;
        }
    }

    &__tab--selected &__link,
    &__tab--selected:hover &__link,
    &__tab--selected &__link:hover {
        color: @tabs--selected-color;
        background-color: transparent;

        &::before {
            background-color: @tabs--indicator-color-selected;
        }
    }

    &__tab--closable {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__tab--closable &__link {
        padding-inline-end: 20px;
    }

    &__tab--closable &__tab-close {
        position: absolute;
        inset-block-start: 6px;
        inset-inline-end: 0;
    }

    &__tab-icon + &__tab-title,
    &__tab-title + &__tab-label {
        margin-inline-start: 4px;
    }

    &__link &__tab-icon,
    &__tab:not(&__tab--selected) &__link:hover &__tab-icon {
        color: @tabs--icon-color;
    }

    &__tab &__tab-label {
        box-sizing: border-box;
        min-inline-size: 16px;
        padding-block: 0;
        padding-inline: 4px;
        vertical-align: top;
        border-radius: 8px;
    }

    &__tab-close,
    &__menu-item-close {
        display: inline-block;
        overflow: visible;
        box-sizing: border-box;
        block-size: 16px;
        inline-size: 16px;
        margin: 0;
        padding: 2px;
        cursor: pointer;
        text-align: center;
        vertical-align: top;
        color: @tabs--icon-color;
        border: none;
        border-radius: 2px;
        background-color: transparent;
        font: inherit;
        font-size: 12px;
        line-height: 1;

        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }

        &:hover {
            text-decoration: none;
            background-color: rgb(0 0 0 / 0.05);
        }

        &:active {
            background-color: rgb(0 0 0 / 0.09);
        }

        &:focus {
            outline: none;
            box-shadow: @tabs--focus-shadow;
        }
    }

    &__tab &__tab-close-icon,
    &__menu-item-close &__menu-item-close-icon {
        inline-size: 12px;
        block-size: 12px;
        vertical-align: top;
    }

    .@{menu--prefix-cls}__item &__menu-item-close {
        margin-block-start: 2px;
        margin-inline-start: 4px;
    }

    &__tab:hover &__tab-close,
    .@{menu--prefix-cls}__item:hover &__menu-item-close,
    .@{menu--prefix-cls}__item--active &__menu-item-close,
    &__tab--selected:hover &__tab-close {
        color: @tabs--icon-color;
    }

    &__tab-list--fake {
        inset-inline-start: 0;
        border-block-end: none;
        visibility: hidden;
        block-size: 0;
        position: absolute;
        overflow: hidden;
    }

    &__nav--compact {
        flex-wrap: wrap-reverse;
    }

    &__addon {
        flex: none;
        text-align: end;
        flex-wrap: wrap-reverse;
        display: flex;
        justify-content: flex-end;
    }

    &__nav--compact &__addon {
        flex-basis: 100%;
        margin-inline-start: 0;
        margin-block-end: 4px;
    }

    &__nav--search &__addon {
        flex: 1 1 500px;
    }

    &__nav--compact &__search {
        inline-size: auto;
        display: flex;
        align-self: flex-start;
        padding-inline-start: 16px;
    }

    &__nav:not(&__nav--compact) &__addon {
        padding-inline-start: 16px;
    }

    &__nav--searching {
        position: relative;

        .@{tabs--prefix-cls}__search {
            position: absolute;
            inset-block-end: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            padding-inline-start: 0;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;