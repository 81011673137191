// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../../helpers/variables";
@import "../Button/variables";

.@{list-empty-view--prefix-cls} {
    margin-block: 20px;
    text-align: center;

    &__image {
        @media (min-width: 400px) {
            margin-block-end: 20px;
        }
    }

    &__image img {
        max-inline-size: 100%;
        object-fit: contain;

        @media (max-width: 400px) {
            block-size: auto;
        }
    }

    &__image + &__body {
        padding-block-start: 20px;
    }

    &__title,
    &__description {
        max-inline-size: 500px;
        margin-inline: auto;
    }

    &__title {
        margin-block-end: 14px;
    }

    &__description {
        margin-block-end: 12px;
    }

    &__actions .@{button--prefix-cls} {
        margin-block-start: 8px;
        margin-inline: 4px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;