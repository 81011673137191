// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{select--prefix-cls} {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-inline-size: 200px;
    max-inline-size: 100%;
    vertical-align: middle;
    transition: all linear 0.2s;

    &--size-xs {
        min-inline-size: @select--size-xs;
    }

    &--size-sm {
        min-inline-size: @select--size-sm;
    }

    &--size-md {
        inline-size: @select--size-md;
    }

    &--size-lg {
        inline-size: @select--size-lg;
    }

    &--size-xl {
        inline-size: @select--size-xl;
    }

    &--size-fill {
        inline-size: @select--size-fill;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;