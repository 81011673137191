// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Overlay/variables";
@import "../Drawer/variables";
@import "../Button/variables";
@import "../Heading/variables";
@import "../Tabs/variables";

.drawer-header-actions() {
    position: absolute;
    z-index: 10;
    inset-inline-end: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-inline-end: calc(16px - var(--sideBannerWidth, 340px));
    inline-size: calc(var(--sideBannerWidth, 340px) - 32px);

    --drawerHeaderButtonBackgroundHover: #efefef;
    --drawerHeaderButtonBorder: inset 0 0 0 1px rgb(29 72 112 / 0.1);
}

.@{drawer-header--prefix-cls} {
    position: relative;
    flex: none;
    padding-inline: 24px;

    &__inner {
        display: flex;
        align-items: flex-start;
        padding-block: 20px 16px;
        border-block-end: 1px solid #d3d3de;
    }

    .@{drawer--prefix-cls}__title {
        margin-block-end: 0;
        font-size: @heading--h2-font-size;
        font-weight: 400;
        line-height: @heading--h2-line-height;

        > :last-child {
            margin-block-end: 0;
        }
    }

    .@{drawer--prefix-cls}__title + .@{drawer--prefix-cls}__subtitle {
        margin-block-start: 12px;
    }

    &__content {
        flex-grow: 1;
        align-self: center;
        min-inline-size: 0;
    }

    &__actions {
        display: flex;
        align-items: center;
        min-block-size: 40px;
        margin-block: -4px;
        margin-inline-end: -8px;
    }

    &__button {
        border-radius: 8px;
        background-color: var(--drawerHeaderButtonBackground, #fff);
    }

    &__actions > &__button,
    &__actions > &__button[disabled] {
        &,
        &:hover,
        &:focus,
        &:active {
            border-radius: 8px;
            background-color: var(--drawerHeaderButtonBackground, #fff);
            box-shadow: var(--drawerHeaderButtonBorder, none);
        }
    }

    &__actions > &__button:hover,
    &__actions > &__button:focus {
        background-color: var(--drawerHeaderButtonBackgroundHover, #f4f4f4);
    }

    &__actions > &__button:focus {
        box-shadow: 0 0 0 4px rgb(0 149 255 / 0.24);
    }

    &__heading &__button:hover,
    &__heading &__button:focus,
    &__heading &__button:active {
        background-color: rgb(0 0 0 / 0.05);
        .@{button--prefix-cls}__icon {
            color: rgb(255 255 255 / 0.9);
        }
    }

    &__heading &__button:active {
        background-color: rgb(0 0 0 / 0.09);
    }

    &__actions > &__button {
        margin-inline-start: 8px;
    }

    &__button--back {
        margin-block: -4px;
        margin-inline: -8px 8px;
    }

    &__tabs {
        margin-block: 14px -17px;

        .@{tabs--prefix-cls}__nav {
            margin-block-end: 0;
        }

        .@{tabs--prefix-cls}__tab-list {
            border-block-end-color: transparent;
        }
    }

    .@{overlay--prefix-cls}--right & .@{button--prefix-cls}--ghost .@{button--prefix-cls}__icon {
        transform: scale(-1);
    }

    @media (min-width: 850px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--xs &__actions {
            .drawer-header-actions();
        }
    }

    @media (min-width: 1000px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--sm &__actions {
            .drawer-header-actions();
        }
    }

    @media (min-width: 1240px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--md &__actions {
            .drawer-header-actions();
        }
    }

    @media (min-width: 15400px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--lg &__actions {
            .drawer-header-actions();
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;