// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

/*
Rating

Markup:
<div class="rating {{.modifier}}">
    <div class="rating__rates"></div>
</div>

<div class="rating rating--stars">
    <div class="rating__rates">
        <input type="radio" id="rating-100-false-242919" name="rating-option" value="100">
        <label for="rating-100-false-242919" class="rating__indicator"></label>
        <input type="radio" id="rating-80-false-242919" name="rating-option" value="80">
        <label for="rating-80-false-242919" class="rating__indicator"></label>
        <input type="radio" id="rating-60-false-242919" name="rating-option" value="60">
        <label for="rating-60-false-242919" class="rating__indicator"></label>
        <input type="radio" id="rating-40-false-242919" name="rating-option" value="40">
        <label for="rating-40-false-242919" class="rating__indicator"></label>
        <input type="radio" id="rating-20-false-242919" name="rating-option" value="20">
        <label for="rating-20-false-242919" class="rating__indicator"></label>
    </div>
</div>

Modifiers:
.rating--static
.rating--static-total
.rating--small
*/

@rating--prefix-cls: ~"@{cls-prefix}rating";

@rating--animation: ~"@{cls-prefix}animation--rate";

.@{rating--prefix-cls} {
    position: relative;
    display: inline-block;
    overflow: hidden;

    &__rates > input[type="radio"] {
        appearance: none;
        position: absolute;
        inline-size: inherit;
        block-size: inherit;
        opacity: 0;
        inset-inline-start: 0;
        inset-block-start: 0;
        margin: 0;
        z-index: -1;

        &:checked,
        &:focus,
        &:hover {
            ~ .@{rating--prefix-cls}__indicator {
                background-position: 50% -55px;
            }
        }

        &.checked ~ .@{rating--prefix-cls}__indicator {
            animation: @rating--animation 0.5s;
        }
    }

    &__indicator {
        position: relative;
        display: inline-block;
        float: inline-end;
        box-sizing: content-box;
        inline-size: 36px;
        block-size: 32px;
        cursor: pointer;
        background: url("./images/rating.svg") 50% 2px no-repeat;
        background-size: 32px auto;
        text-indent: -9999px;
    }

    &--static {
        inline-size: 70px;
        block-size: 12px;
        margin-block-start: 4px;
        vertical-align: -2px;

        &:not(.@{rating--prefix-cls}--static-total),
        .@{rating--prefix-cls}__rates {
            background: url("./images/rating.svg") 0 0 repeat-x;
            background-size: 14px auto;
        }

        .@{rating--prefix-cls}__rates {
            float: inline-start;
            block-size: 12px;
            background-position: 0 -25px;

            &[data-rate="5"] {
                inline-size: 100%;
            }

            &[data-rate="4"] {
                inline-size: 80%;
            }

            &[data-rate="3"] {
                inline-size: 60%;
            }

            &[data-rate="2"] {
                inline-size: 40%;
            }

            &[data-rate="1"] {
                inline-size: 20%;
            }
        }
    }

    &--static-total .@{rating--prefix-cls}__rates {
        float: inline-end;
        background-position: 100% -50px;
    }

    &--small {
        &.@{rating--prefix-cls}--static {
            inline-size: 50px;
            block-size: 10px;

            &:not(.@{rating--prefix-cls}--static-total),
            .@{rating--prefix-cls}__rates {
                background-size: 10px auto;
            }

            .@{rating--prefix-cls}__rates {
                block-size: 10px;
                background-position: 0 -18px;
            }
        }

        &.@{rating--prefix-cls}--static-total .@{rating--prefix-cls}__rates {
            background-position: 100% -36px;
        }
    }
}

@keyframes @rating--animation {
    from {
        transform: scale(1.2) rotate(0.02deg);
    }

    to {
        transform: scale(1);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;