// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

@import "variables";

.@{skeleton--prefix-cls}-text {
    display: contents;

    &__line {
        transform: scale(1, 0.5);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;