// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{combobox--prefix-cls} {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    inline-size: @combobox--size-md;
    max-inline-size: 100%;
    vertical-align: middle;
    transition: all linear 0.2s;

    &--size-sm {
        inline-size: @combobox--size-sm;
    }

    &--size-md {
        inline-size: @combobox--size-md;
    }

    &--size-lg {
        inline-size: @combobox--size-lg;
    }

    &--size-xl {
        inline-size: @combobox--size-xl;
    }

    &--size-fill {
        inline-size: @combobox--size-fill;
    }

    &__input {
        &:hover,
        &:focus,
        &:active {
            .@{combobox--prefix-cls}__dropdown-button {
                color: @combobox--dropdown-button-color-hover;
            }
        }

        .@{combobox--prefix-cls}__dropdown-button {
            border: none;
            background: none;
            padding: 0;
            display: inline-block;
            block-size: 28px;
            inline-size: 28px;
            color: @combobox--dropdown-button-color;
        }

        &--disabled {
            &,
            &:hover,
            &:focus,
            &:active {
                cursor: not-allowed;

                // stylelint-disable-next-line max-nesting-depth
                .@{combobox--prefix-cls}__dropdown-button {
                    pointer-events: none;
                    color: @combobox--dropdown-button-color;
                }
            }
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;