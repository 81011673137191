// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";
@import "fonts";

html {
    overflow-y: scroll;
    font-family: @base-body-font-family;
    font-size: @base-body-font-size;
    font-weight: @base-body-font-weight;
    line-height: @base-body-line-height;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0);
    inline-size: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/*  rtl:raw:
    html { direction: rtl; }
    html * { unicode-bidi: embed; }
*/

html,
body {
    block-size: 100%;
    min-block-size: 100%;
}

body {
    margin: 0;
    background-color: @base-body-background;
    color: @base-body-color;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

progress {
    vertical-align: baseline;
}

template,
[hidden] {
    display: none;
}

iframe {
    border: 0;
}

a,
area,
button,
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

hr {
    box-sizing: content-box;
    block-size: 0;
    overflow: visible;
    margin-block: @base-hr-margin-vertical;
    margin-inline: 0;
    border: 0;
    border-block-start: @base-hr-border-width solid @base-hr-border;
}

pre {
    padding-block: 12px;
    padding-inline: 16px;
    background-color: @base-pre-background;
    font: @base-pre-font-size / @base-pre-line-height @base-pre-font-family;
    color: @base-pre-color;
    tab-size: 4;
    overflow: auto;
}

a {
    color: @base-link-color;
    text-decoration: @base-link-text-decoration;
    cursor: pointer;
    background-color: transparent;

    &:active,
    &:hover {
        outline: none;
    }

    &:hover {
        color: @base-link-hover-color;
        text-decoration: @base-link-hover-text-decoration;
    }
}

[tabindex="-1"]:focus {
    outline: none;
}

b,
strong {
    font-weight: bold;
}

code,
kbd,
samp {
    font-family: @base-code-font-family;
    font-size: @base-code-font-size;
    color: @base-code-color;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    inset-block-end: -0.25em;
}

sup {
    inset-block-start: -0.5em;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

audio,
canvas,
img,
video {
    box-sizing: border-box;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

textarea {
    overflow: auto;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    inline-size: 100%;
    max-inline-size: 100%;
    padding: 0;
    white-space: normal;
}

ul,
ol {
    padding-inline-start: @base-list-padding-left;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
    margin-block-start: 0;
    margin-block-end: @base-margin-vertical;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-block-start: 0;
    margin-block-end: @base-margin-vertical;
    font-family: @base-heading-font-family;
    font-weight: @base-heading-font-weight-regular;
    color: @base-heading-color;
    text-transform: @base-heading-text-transform;
}

h4,
h5,
h6 {
    font-weight: @base-heading-font-weight-bold;
}

h1 {
    font-size: @base-h1-font-size;
    line-height: @base-h1-line-height;
}

h2 {
    font-size: @base-h2-font-size;
    line-height: @base-h2-line-height;
}

h3 {
    font-size: @base-h3-font-size;
    line-height: @base-h3-line-height;
}

h4 {
    font-size: @base-h4-font-size;
    line-height: @base-h4-line-height;
}

h5 {
    font-size: @base-h5-font-size;
    line-height: @base-h5-line-height;
}

h6 {
    font-size: @base-h6-font-size;
    line-height: @base-h6-line-height;
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;