// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Action/variables";

.@{status--prefix-cls} {
    color: @status--color;

    &--on-dark {
        color: @status--on-dark-inactive-color;
    }

    &--danger .@{action--prefix-cls}__icon {
        color: @status--danger-color;
    }

    &--warning .@{action--prefix-cls}__icon {
        color: @status--warning-color;
    }

    &--success .@{action--prefix-cls}__icon {
        color: @status--success-color;
    }

    &--info .@{action--prefix-cls}__icon {
        color: @status--info-color;
    }

    &--inactive .@{action--prefix-cls}__icon {
        color: @status--inactive-color;
    }

    &--on-dark&--danger .@{action--prefix-cls}__icon {
        color: @status--on-dark-danger-color;
    }

    &--on-dark&--warning .@{action--prefix-cls}__icon {
        color: @status--on-dark-warning-color;
    }

    &--on-dark&--success .@{action--prefix-cls}__icon {
        color: @status--on-dark-success-color;
    }

    &--on-dark&--info .@{action--prefix-cls}__icon {
        color: @status--on-dark-info-color;
    }

    &--on-dark&--inactive .@{action--prefix-cls}__icon {
        color: @status--on-dark-inactive-color;
    }

    &--compact {
        font-size: 12px;
        line-height: 16px;

        .@{status--prefix-cls}::before {
            content: "";
            display: inline-block;
            border-radius: 50%;
            inline-size: 8px;
            block-size: 8px;
            vertical-align: 0;
            background-color: @status--inactive-color;
        }

        &:not(.@{action--prefix-cls}--icon) {
            padding-inline-start: 12px;
        }

        &:not(.@{action--prefix-cls}--icon) .@{status--prefix-cls}::before {
            float: inline-start;
            margin-block: 4px;
            margin-inline: -12px 4px;
            vertical-align: top;
        }

        &.@{action--prefix-cls}--progress .@{status--prefix-cls}::before {
            display: none;
        }

        &.@{action--prefix-cls}--progress .@{action--prefix-cls}__icon {
            inline-size: 8px;
            block-size: 8px;
            margin-block-start: -2px;
            margin-inline: -12px 4px;
        }

        &.@{status--prefix-cls}--flip.@{action--prefix-cls}--progress .@{action--prefix-cls}__icon {
            float: inline-end;
            margin-block-start: 4px;
            margin-inline: 0 -12px;
        }

        .@{status--prefix-cls}--danger::before {
            background-color: @status--danger-color;
        }

        .@{status--prefix-cls}--warning::before {
            background-color: @status--warning-color;
        }

        .@{status--prefix-cls}--success::before {
            background-color: @status--success-color;
        }

        .@{status--prefix-cls}--info::before {
            background-color: @status--info-color;
        }

        .@{status--prefix-cls}--inactive::before {
            background-color: @status--inactive-color;
        }
    }

    &-on-dark&--compact {
        .@{status--prefix-cls}::before {
            background-color: @status--on-dark-inactive-color;
        }

        .@{status--prefix-cls}--danger::before {
            background-color: @status--on-dark-danger-color;
        }

        .@{status--prefix-cls}--warning::before {
            background-color: @status--on-dark-warning-color;
        }

        .@{status--prefix-cls}--success::before {
            background-color: @status--on-dark-success-color;
        }

        .@{status--prefix-cls}--info::before {
            background-color: @status--on-dark-info-color;
        }

        .@{status--prefix-cls}--inactive::before {
            background-color: @status--on-dark-inactive-color;
        }
    }

    &--compact&--flip:not(.@{action--prefix-cls}--icon) {
        padding-inline-start: 0;
        padding-inline-end: 12px;

        .@{status--prefix-cls}::before {
            float: inline-end;
            margin-block: 4px;
            margin-inline: 4px -12px;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;