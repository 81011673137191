// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Section/variables";

@form-field-text--prefix-cls: ~"@{cls-prefix}form-field-text";

.@{form-field-text--prefix-cls} {
    &--multiline:not(.@{section-item--prefix-cls}--vertical) .@{section-item--prefix-cls}__title {
        align-self: flex-start;
        margin-block-start: 4px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;