// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

/*
Switch

Markup:
<label class="switch {{.modifier}}">
    <span class="switch__label">Text</span>
    <input type="checkbox" :modifier />
    <span class="switch__indicator" />
</label>

Modifiers:
.switch--warning
.switch--danger
.switch--loading
:disabled
*/

.@{switch--prefix-cls} {
    cursor: pointer;
    position: relative;
    display: inline-block;
    block-size: 20px;

    label {
        user-select: none;
    }

    &__label {
        display: inline-block;
        margin-inline-end: 5px;
        vertical-align: middle;
        user-select: none;
    }

    &__indicator {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        position: relative;
        block-size: 16px;
        inline-size: 30px;
        margin-block: 2px;
        margin-inline: 1px;
        line-height: 16px;
        color: @switch--off-color;
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        vertical-align: middle;
        background-color: @switch--off-background-color;
        border-radius: 8px;
        transition: 0.3s ease-out;

        &:hover {
            background-color: @switch--hover-background-color;
        }

        &::after {
            position: relative;
            content: "";
            display: block;
            inline-size: 14px;
            block-size: 14px;
            margin: 1px;
            background-color: #fff;
            border-radius: 50%;
        }
    }

    input[type="checkbox"] {
        appearance: none;
        position: absolute;
        inline-size: inherit;
        block-size: inherit;
        opacity: 0;
        inset-inline-start: 0;
        inset-block-start: 0;
        z-index: -1;
    }

    input:checked ~ .@{switch--prefix-cls}__indicator {
        background-color: @switch--on-background-color;
        padding-inline-start: 14px;

        &:hover {
            background-color: @switch--hover-background-color;
        }
    }

    input:not(:disabled):focus {
        outline: none;

        ~ .@{switch--prefix-cls}__indicator {
            background-color: @switch--off-background-color;
        }
        &:checked + .@{switch--prefix-cls}__indicator {
            background-color: @switch--on-background-color;
        }

        ~ .@{switch--prefix-cls}__indicator,
        &:checked + .@{switch--prefix-cls}__indicator {
            box-shadow: @switch--shadow-focus;

            &:hover {
                background-color: @switch--hover-background-color;
            }
        }
    }

    input:disabled {
        ~ .@{switch--prefix-cls}__indicator {
            background-color: @switch--off-background-color;
            opacity: 0.47;
            cursor: not-allowed;

            &::after {
                background-color: #fff;
                box-shadow: none;
            }
        }

        &:checked ~ .@{switch--prefix-cls}__indicator {
            background-color: @switch--on-background-color;
        }
    }

    &--warning {
        input:checked ~ .@{switch--prefix-cls}__indicator {
            background-color: @switch--warning-checked-background-color;
        }

        input:not(:disabled):checked:focus + .@{switch--prefix-cls}__indicator {
            background-color: @switch--warning-checked-indicator-color;
        }

        input:disabled:checked ~ .@{switch--prefix-cls}__indicator {
            background-color: @switch--warning-checked-background-color-disabled;
        }
    }

    &--danger {
        input:checked ~ .@{switch--prefix-cls}__indicator {
            background-color: @switch--danger-checked-background-color;
        }

        input:not(:disabled):focus:checked + .@{switch--prefix-cls}__indicator {
            background-color: @switch--danger-checked-indicator-color;
        }

        input:disabled:checked ~ .@{switch--prefix-cls}__indicator {
            background-color: @switch--danger-checked-background-color-disabled;
        }
    }

    &--loading &__indicator {
        background: @switch--hover-background-color;
        opacity: 1;
        padding-inline-start: 8px;

        &::after {
            animation: @switch--loading-animation 0.8s linear infinite;
        }
    }

    // TODO Move to the objects-toolbar component
    .objects-toolbar > & {
        float: inline-start;
        margin-block: 0;
        margin-inline: 10px;

        @media (min-width: @screen-sm) {
            margin-block: 4px 8px;
            margin-inline: 0 8px;
        }
    }
}

@keyframes @switch--loading-animation {
    0%,
    100% {
        transform: scale(0.4) translateX(0) translateY(-50%);
    }

    25% {
        transform: scale(0.4) translateX(-50%) translateY(0);
    }

    50% {
        transform: scale(0.4) translateX(0) translateY(50%);
    }

    75% {
        transform: scale(0.4) translateX(50%) translateY(0);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;