// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Icon/variables";

.@{media--prefix-cls} {
    display: flex;
    align-items: flex-start;

    &--vertical {
        flex-direction: column;
    }

    &--start {
        align-items: flex-start;
    }

    &--center {
        align-items: center;
    }

    &--end {
        align-items: flex-end;
    }

    &--vertical&--start {
        text-align: start;
    }

    &--vertical&--center {
        text-align: center;
    }

    &--vertical&--end {
        text-align: end;
    }

    &__figure {
        flex-shrink: 0;

        &--start {
            align-self: flex-start;
        }

        &--center {
            align-self: center;
        }

        &--end {
            align-self: flex-end;
        }

        .@{icon--prefix-cls} {
            vertical-align: top;
        }
    }

    &:not(&--vertical) &__figure {
        margin-block: 0;
        margin-inline: 0 @media--image-spacing-md;

        &--sm {
            margin-inline-end: @media--image-spacing-sm;
        }

        &--md {
            margin-inline-end: @media--image-spacing-md;
        }

        &--lg {
            margin-inline-end: @media--image-spacing-lg;
        }

        &--xl {
            margin-inline-end: @media--image-spacing-xl;
        }

        &--xxl {
            margin-inline-end: @media--image-spacing-xxl;
        }

        .@{icon--prefix-cls}--size-12 {
            margin-block-start: 4px;
        }

        .@{icon--prefix-cls}--size-16 {
            margin-block-start: 2px;
        }
    }

    &--vertical &__figure {
        margin-block: 0 @media--image-spacing-md;
        margin-inline: 0;

        &--sm {
            margin-block-end: @media--image-spacing-sm;
        }

        &--md {
            margin-block-end: @media--image-spacing-md;
        }

        &--lg {
            margin-block-end: @media--image-spacing-lg;
        }

        &--xl {
            margin-block-end: @media--image-spacing-xl;
        }

        &--xxl {
            margin-block-end: @media--image-spacing-xxl;
        }
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &--vertical&--center &__header {
        justify-content: center;
    }

    &--vertical&--end &__header {
        justify-content: flex-end;
    }

    &__title {
        display: block;
        margin-block-end: 0;
    }

    &__header--md &__title {
        font-size: @media--title-md-font-size;
        line-height: @media--title-md-line-height;
    }

    &__header--lg &__title {
        font-size: @media--title-lg-font-size;
        line-height: @media--title-lg-line-height;
    }

    &__header--xl &__title {
        font-size: @media--title-xl-font-size;
        line-height: @media--title-xl-line-height;
    }

    &__title-addon {
        margin-inline-start: 4px;
    }

    &__body {
        flex: 1;
        min-inline-size: 0;
    }

    &__header + &__content {
        padding-block-start: 4px;
    }

    &__content > :last-child {
        margin-block-end: 0;
    }
}

.@{media-section--prefix-cls} {
    margin-block-end: 8px;
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;