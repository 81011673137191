// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Tabs/variables";

.@{skeleton--prefix-cls}-tabs {
    display: flex;
    align-items: flex-end;
    margin-block-end: 16px;
    border-block-end: 2px solid @tabs--border-color;
    gap: 24px;
    line-height: 26px;
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;